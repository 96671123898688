<template>
    <div class="lifeHouse">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="banner">
                
                <el-carousel>
                    <el-carousel-item v-for="item in lifeHouseBannerList" :key="item.sort" @click.native="clickBannerImg(item.linkUrl)">
                        <!-- <img src="../assets/imgs/place51.png"/> -->
                        <img :style="{'cursor':item.linkUrl?'pointer':''}" :src="item.bannerImgUrl"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="useTypes" v-if="guideList.length>0">
                <!-- <div class="item">
                    <div class="img">
                        <img src="../assets/imgs/material1.png"/>
                    </div>
                    <div class="text">墙地面材料</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="../assets/imgs/material2.png"/>
                    </div>
                    <div class="text">卫浴用品</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="../assets/imgs/material3.png"/>
                    </div>
                    <div class="text">厨房用品</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="../assets/imgs/material4.png"/>
                    </div>
                    <div class="text">装饰材料</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="../assets/imgs/material5.png"/>
                    </div>
                    <div class="text">电工五金</div>
                </div>
                <div class="item">
                    <div class="img">
                        <img src="../assets/imgs/material6.png"/>
                    </div>
                    <div class="text">家居软装</div>
                </div> -->


                <div class="item" :class="{'active':inputSearchTypeId==item.goodsTypeId}" v-for="(item,i) in guideList" :key="i" @click="jumpSearch(item)">
                    <div class="img">
                        <img :src="item.url"/>
                    </div>
                    <div class="text">{{item.guideName}}</div>
                </div>
            </div>

            <searchGoodsModule :isImport="1" :inputTypeId="inputSearchTypeId" :inputTypeIdArr="guideListIds" v-if="showSearchList"></searchGoodsModule>

            <div class="areaName" v-if="false">
                <!-- <span>热卖商品</span> -->
                <span>热销商品</span>
                <div class="more" v-show="false">
                    <!-- <img src="../assets/imgs/more1.png"/> -->
                    更多
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="typeGoods" v-if="false">
                <div class="item" v-for="(item,i) in goodsList" :key="i" @click="clickGoods(item)">
                    <div class="img">
                        <img :src="item.cover?item.cover:require('../assets/imgs/place11.png')"/>
                    </div>
                    <div class="price">
                        <div class="left">
                            <span>￥</span>{{item.ourShopPrice}}
                            <span class="pick">唯选</span>
                        </div>
                        <div class="right">
                            {{item.brandName}}
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="intro">
                        <span class="tag">【{{item.brandName}}】</span>
                        {{item.name}} 
                    </div>
                </div>
            </div>
            <div class="areaName" v-if="false">
                <span>热门品牌</span>
                <div class="more" @click="$router.push('/brandfest')">
                    更多
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="brands" v-if="false">
                <div class="item" v-for="(item,i) in brandList" :key="i" @click="clickHotBrand(item)">
                    <!-- <img src="../assets/imgs/place50.png"/> -->
                    <img :src="item.brandLogo"/>
                </div>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import searchGoodsModule from "@/views/Merchandise.vue"
import {getBuildMaterialGoodsList,getBuildMaterialBrandList,getWebsiteSetting,getWebsiteSettingColumnGuide} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp,searchGoodsModule
    },
    data(){
        return{
            goodsList:[],
            typeList:[
                {
                    dictLabel:"冰箱",
                    dictValue:1,
                },
                {
                    dictLabel:"空调",
                    dictValue:2,
                },
                {
                    dictLabel:"洗衣机",
                    dictValue:3,
                },
                {
                    dictLabel:"电视",
                    dictValue:4,
                },
                {
                    dictLabel:"扫地机器人",
                    dictValue:5,
                },
                {
                    dictLabel:"智能安防",
                    dictValue:6,
                },
            ],
            typeValue:1,
            goodsList:[],
            brandList:[],
            lifeHouseBannerList:[],//banner列表
            guideList:[],//导航列表
            inputSearchTypeId:"",
            showSearchList:false,
            guideListIds:[],
        }
    },
    methods: {
        // 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				host:location.host,
				// host:"www.i-dian.com",
			}).then(res=>{
                // console.log("网站配置",res);
                if(res.code==200){
                    if(res.data.websiteLogo){
                        this.websiteLogo=res.data.websiteLogo;
                    }
                    if(res.data.bannerPlaceList){
                        res.data.bannerPlaceList.forEach(item=>{
                            if(item.bannerPlace==5){
                                this.lifeHouseBannerList=item.bannerSettingList
                            }
                        })
                    }
                }
			})
		},
        clickBannerImg(url){
			// // console.log(url)
			if(url){
				window.open(url,'_blank')
			}
		},
        clickGoods(item){
            this.$router.push({
                path:'/furnitureDetail',
                query:{goodsId:item.goodsId}
            })
        },
        clickType(){},
        // 获取热卖商品列表
        gainBuildMaterialGoods(){
            getBuildMaterialGoodsList().then(res=>{
                // console.log("热卖商品列表",res);
                if(res.code==200){
                    this.goodsList=res.data;
                }
            })
        },
        // 获取热门品牌列表
        gainBrandList(){
            getBuildMaterialBrandList().then(res=>{
                // console.log("热门品牌列表",res);
                if(res.code==200){
                    this.brandList=res.data;
                }
            })
        },
        clickHotBrand(item){
			sessionStorage.removeItem('searchKeyword')
            sessionStorage.brandId=item.brandId;
            this.$router.push({
                path:"/merchandise",
                query:{fromBrand:true,pa:"bi_"+item.brandId}
            })
        },
        // 获取导航内容
        gainGuide(){
            getWebsiteSettingColumnGuide({type:12}).then(res=>{
                // console.log("导航内容",res);
                if(res.code==200){
                    this.guideList=res.data;
                    if(this.guideList.length>0){
                        this.guideListIds=this.guideList.map(item=>item.goodsTypeId)
                        this.showSearchList=true;
                    }
                }
            })
        },
        // 跳转搜索
        jumpSearch(item){
            // sessionStorage.removeItem('searchKeyword');
            // sessionStorage.home_typeId=item.goodsTypeId;
            // sessionStorage.home_typeName=item.guideName;
            // this.$router.push({
			// 	path:"merchandise",
			// 	query:{fromHome:true}
			// })

            this.inputSearchTypeId=item.goodsTypeId
        }
    },
    created() {
        this.gainWebsiteSetting();
        // this.gainBuildMaterialGoods();
        // this.gainBrandList();
        this.gainGuide();
    },
}
</script>

<style lang="less" scoped>
.lifeHouse{
    .banner{
        margin-top: 40px;
        // height: 623px;
        // &>img{
        //     width: 100%;
        //     height: 100%;
        // }
        height: 623px;
        .el-carousel{
            height: 100%;
            /deep/.el-carousel__container{
                height: 100%;
            }
            /deep/.el-carousel__item{
                img{
                    height: 100%;
                    width: 100%;
                    // object-fit: cover;
                }
            }
        }
    }
    .useTypes{
        margin-top: 90px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .item{
            // width: 237px;
            width: calc(100% / 6);
            height: 157px;
            border-right: 1px solid rgba(230, 230, 230, 1);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &:hover{
                .img{
                    img{
                        width: calc(100% + 10px);
                        height: calc(100% + 10px);
                    }
                }
            }
            &>.img{
                // width: 65px;
                // height: 65px;
                width: 50px;
                height: 50px;
                position: relative;
                &>img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                }
            }
            .text{
                margin-top: 15px;
                // font-size: 26px;
                font-size: 20px;
                font-weight: 400;
                color: #333;
            }
            &:last-child{
                border-right: 0;
            }

            &.active{
                background: rgba(35, 125, 92,0.15);
            }
        }
    }
    .areaName{
        margin: 46px 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &>span:nth-child(1){
            font-size: 20px;
            font-weight: 500;
            color: #333;
        }
        .more{
            cursor: pointer;
            width: 73px;
            height: 32px;
            border-radius: 24px;
            background: rgba(229, 229, 229, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            padding-left: 5px;
            padding-bottom: 1px;
            i{
                // margin-left: 4px;
                // margin-top: 2px;
            }
            &:hover{
                background: rgba(225, 242, 236, 1);
                color: rgba(37, 126, 93, 1);
            }
        }
        .right{
            font-size: 24px;
            font-weight: 400;
            line-height: 34.75px;
            cursor: pointer;
        }
    }
    .typeGoods{
        // margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 337px;
            margin-top: 40px;
            margin-right: 40px;
            cursor: pointer;
            background: rgba(245, 245, 245, 1);
            padding-bottom: 10px;
            border-radius: 6px 6px 0 0;
            &:nth-child(4n){
                margin-right: 0;
            }
            &>.img{
                width: 337px;
                height: 252px;
                position: relative;
                overflow: hidden;
                border-radius: 6px 6px 0 0;
                &>img{
                    width: 337px;
                    height: 252px;
                    object-fit: cover;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    object-fit: cover;
                    border-radius: 6px 6px 0 0;
                }
            }
            .price{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 13px;
                padding: 0 10px;
                .left{
                    font-size: 22px;
                    font-weight: 700;
                    color: rgba(200, 64, 57, 1);
                    &>span{
                        font-size: 14px
                    }
                    .pick{
                        color: #DCB08E;
                        border: 0.5px solid #DCB08E;
                        margin-left: 10px;
                        padding: 2px 5px;
                    }
                }
                .right{
                    font-size: 12px;
                    color: #fff;
                    background: linear-gradient(to right,#FD9A27,#FD6F22);
                    border-radius: 10px;
                    height: 26px;
                    border-radius: 13px;
                    display: flex;
                    align-items: baseline;
                    padding: 4px 10px;
                    cursor: pointer;
                    &>i{
                        margin-left: 2px;
                    }
                }
            }
            .intro{
                padding: 0 10px;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: -0.7px;
                line-height: 20px;
                color: rgba(56, 56, 56, 1);
                margin-top: 6px;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:2;
                line-clamp:2;
                .tag{
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: rgba(158, 110, 62, 1);
                }
            }
            &:hover{
                .img{
                    img{
                        width: 357px;
                        height: 272px;
                    }
                }
            }
        }
    }
    .brands{
        margin-top: 30px;
        border: 1px solid rgba(230, 230, 230, 1);
        display: flex;
        flex-wrap: wrap;
        .item{
            width: calc(100% / 6);
            height: 200px;
            border-right: 1px solid rgba(230, 230, 230, 1);
            border-bottom: 1px solid rgba(230, 230, 230, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;
            &:hover{
                &>img{
                    width: calc(80% + 10px);
                    height: calc(80% + 10px);
                }
            }
            &>img{
                width: 80%;
                height: 80%;
                object-fit: cover;
                transition: all 0.3s;
            }
            &:nth-child(6n){
                border-right: none;
            }
        }
    }
}
</style>